<template>
  <component class="status__icon" :is="iconType.icon" :class="iconType.class" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      require: true,
    },
  },
  computed: {
    iconType() {
      switch (this.type) {
        case 'assigned':
          return {
            icon: 'timer-sand-icon',
            class: 'assigned',
          };
        case 'completed':
        case 'inProcess':
          return {
            icon: 'circle-icon',
            class: 'process',
          };
        case 'finished':
          return {
            icon: 'check-circle',
            class: 'resolved',
          };
        case 'failed':
        case 'closed':
          return {
            icon: 'close-circle-icon',
            class: 'cancel',
          };
        case 'offHours':
          return {
            icon: 'clock-outline-icon',
            class: 'outOfTime',
          };
        case 'offlineAgents':
          return {
            icon: 'account-off-outline-icon',
            class: 'disconnected',
          };
        case 'bot':
          return {
            icon: 'webchat',
            class: 'ivr',
          };
        case 'pending':
          return {
            icon: 'progress-click-icon',
            class: 'noStatus',
          };
        default:
          return {
            icon: 'help-circle-icon',
            class: 'noStatus',
          };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.status__icon {
  margin-left: 25px;
  font-size: 20px;

  &.assigned {
    color: $sellia-yellow;
  }

  &.process {
    color: $alert-green;
  }

  &.resolved {
    color: $primary;
  }

  &.cancel {
    color: $alert-red;
  }

  &.outOfTime {
    color: $gray-dark;
  }

  &.disconnected {
    color: $alert-blue;
  }

  &.ivr {
    color: $alert-blue;
  }

  &.noStatus {
    color: $sellia-orange;
  }
}
</style>
